import React, { useEffect, useState } from "react";
import LazyLoad from "react-lazy-load";

function AboutUS() {
  const [aboutData, setAboutData] = useState();
  let [aboutBanner, setAboutBanner] = useState();
  let [bannerHeading, setBannerHeading] = useState();
  let [aboutHeading, setAboutHeading] = useState();
  let [aboutContentBold, setAboutContentBold] = useState();
  let [aboutContent, setAboutContent] = useState();
  let [aboutSecImg, setAboutSecImg] = useState();
  let [ourServicesList, setOurServicesList] = useState();
  const [servicesData, setServicesData] = useState({});
  let [searchJob, setSearchJob] = useState("");

  useEffect(() => {
    let aboutDataCall = async () => {
      // try {
      let aboutDataResponse = await fetch(
        `${process.env.REACT_APP_HOST_URL}wp-json/wp/v2/pages/493`
      );
      let data = await aboutDataResponse.json();
      setAboutData(data);
      // } catch (error) {
      //   console.log("Error : ", error);
      // }
    };
    aboutDataCall();
  }, []);

  useEffect(() => {
    if (aboutData) {
      // console.log(aboutData);
      setBannerHeading(aboutData.acf.banner_heading);
      setAboutHeading(aboutData.acf.about_heading);
      setAboutContentBold(aboutData.acf.about_content_bold);
      setAboutContent(aboutData.acf.about__content);
      setOurServicesList(aboutData.acf.our_services_list);
      setSearchJob(aboutData.acf.search_job);
    }
  }, [aboutData]);

  useEffect(() => {
    const about_Page_Images = async () => {
      let aboutPageImagesResponse = await fetch(
        `${process.env.REACT_APP_HOST_URL}/wp-json/jobwish/v1/imagesForWeb`
      );
      let imageResponse = await aboutPageImagesResponse.json();
      // console.log(imageResponse);
      setAboutBanner(imageResponse.data.About_page.about_BannerImage);
      setAboutSecImg(imageResponse.data.About_page.about_detailSecImg);
    };
    about_Page_Images();
  }, []);

  useEffect(() => {}, [aboutBanner]);
  useEffect(() => {}, [aboutSecImg]);
  useEffect(() => {}, [searchJob]);

  useEffect(() => {
    const convertServicesListToData = async () => {
      if (!ourServicesList) {
        // Guard against undefined or null
        return;
      }

      const resultObject = {};

      for (const key in ourServicesList) {
        if (ourServicesList.hasOwnProperty(key)) {
          const item = ourServicesList[key];

          // Assuming the structure of each item is like {img: ..., heading: ..., content: ...}
          const mediaId = item.img;

          try {
            // Fetch image URL using the WordPress REST API
            const response = await fetch(
              `${process.env.REACT_APP_HOST_URL}wp-json/wp/v2/media/${mediaId}`
            );
            const imageData = await response.json();

            // Update the result object with the image URL
            resultObject[key] = {
              img: imageData.source_url,
              heading: item.heading,
              content: item.content,
            };
          } catch (error) {
            console.error(
              `Error fetching image data for media ID ${mediaId}:`,
              error
            );
          }
        }
      }

      // Set the result object to the servicesData state
      setServicesData(resultObject);
    };

    // Call the conversion function
    convertServicesListToData();
  }, [ourServicesList]);

  return (
    <>
      <section className="job-list-hero blog-hero">
        <LazyLoad className="AboutBanrImg blog-hero">
          <img src={aboutBanner} alt="" />
        </LazyLoad>
        <div className="bnr-blog-content">
          <h1>{bannerHeading}</h1>
        </div>
      </section>

      <section className="breadcb about-us">
        <div className="container">
          <div className="row">
            <div className="breadcrumb">
              <span property="itemListElement" typeof="ListItem">
                <a
                  property="item"
                  typeof="WebPage"
                  title="Categories"
                  href="/categories/"
                  className="archive post-product-archive"
                >
                  <span property="name">Heim</span>
                </a>
                <meta property="position" content="1" />
              </span>
              <div className="bacsp">/</div>
              <span property="itemListElement" typeof="ListItem">
                <span
                  property="name"
                  className="archive taxonomy product_cat current-item"
                >
                  Über uns{" "}
                </span>
                <meta
                  property="url"
                  content="https://bromicpgstg.wpengine.com/product-category/gas-components/"
                />
                <meta property="position" content="2" />
              </span>
            </div>
          </div>
          <div className="about-row">
            <div className="about-content">
              <h1 className="title">{aboutHeading}</h1>
              <p className="bold-text">{aboutContentBold}</p>
            </div>
            <div className="about-collage">
              <img src={aboutSecImg} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
        <div className="aboutInfo">
          <p
            className="light-text"
            dangerouslySetInnerHTML={{ __html: aboutContent }}
          />
          <a href={searchJob.url} className="btn btn-red ">
            {searchJob.title}
          </a>
        </div>
      </section>

      <section className="our-service">
        <div className="container">
          <h2 className="title">Unser Service</h2>
          <div className="row">
            {/* Access data from servicesData object */}
            {Object.keys(servicesData).map((key) => (
              <div className="item" key={key}>
                <span>
                  <img src={servicesData[key].img} alt={key} />
                </span>
                <div className="content">
                  <h6>{servicesData[key].heading}</h6>
                  <p>{servicesData[key].content}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
export default AboutUS;
