import React, { useEffect, useState } from "react";
import LazyLoad from "react-lazy-load";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import CountUp from "react-countup";

function Home() {
  const [homeData, setHomeData] = useState();
  let [bannerImage, setBannerImage] = useState([]);
  let [jobNumber, setJobnumber] = useState();
  let [bannerHeading, setBannerHeading] = useState();
  let [bannerContent, setBannerContent] = useState();
  let [preHeadingSection, setpreHeadingSection] = useState();
  let [headingSection, setheadingSection] = useState();
  let [processHeading1, setprocessHeading1] = useState();
  let [processContent1, setprocessContent1] = useState();
  let [processImg1, setprocessImg1] = useState();
  let [processImg1link, setprocessImg1link] = useState();
  let [processHeading2, setprocessHeading2] = useState();
  let [processContent2, setprocessContent2] = useState();
  let [processImg2, setprocessImg2] = useState();
  let [processImg2link, setprocessImg2link] = useState();
  let [processHeading3, setprocessHeading3] = useState();
  let [processContent3, setprocessContent3] = useState();
  let [processImg3, setprocessImg3] = useState();
  let [processImg3link, setprocessImg3link] = useState();
  let [imageSlider, setImageSlider] = useState([]);
  let [preAboutHeading, setPreAboutHeading] = useState();
  let [aboutHeading, setAboutHeading] = useState();
  let [aboutContent, setAboutContent] = useState();
  let [aboutImage, setAboutImage] = useState();
  let [interViewSectionImage, setInterViewSectionImage] = useState();
  let [interViewSectionheading, setInterViewSectionheading] = useState();
  let [preHeading, setPreHeading] = useState();
  let [heading, setHeading] = useState();
  let [content, setContent] = useState();
  let [appImage, setAppImage] = useState();
  const [jobType, setJobType] = useState([]);
  let [jobTitle, setJobTitle] = useState();
  let [jobLocation, setJobLocation] = useState();
  let [jobSearchtype, setJobSearchtype] = useState();
  let [jobCountWithSkill, setJobCountWithSkill] = useState([]);
  let [interviewQues, setInterviewQues] = useState([]);
  let [employerCount, setEmployerCount] = useState();
  let [candidateCount, setCandidateCount] = useState();
  let [searchJobBtn, setSearchJobBtn] = useState(true);
  let [searchCandidateBtn, setSearchCandidateBtn] = useState(false);
  let [sectorTitle, setSectorTitle] = useState([]);
  let [industryTitle, setIndustryTitle] = useState([]);
  let [sectorName, setSectorName] = useState();
  let [industryName, setIndustryName] = useState();

  useEffect(() => {
    const homeDataResponse = async () => {
      // try {
      const dataResponse = await fetch(
        `${process.env.REACT_APP_HOST_URL}wp-json/wp/v2/pages/428`
      );
      const data = await dataResponse.json();
      setHomeData(data);
      // } catch (error) {
      //   console.log("Error :", error);
      // }
    };
    homeDataResponse();
  }, []);

  useEffect(() => {
    if (homeData) {
      // console.log(homeData);
      setBannerHeading(homeData.acf.banner_heading);
      setBannerContent(homeData.acf.banner_content);
      setpreHeadingSection(homeData.acf.pre_heading_section);
      setheadingSection(homeData.acf.heading_section);
      setprocessHeading1(homeData.acf.process.heading1);
      setprocessContent1(homeData.acf.process.content1);
      setprocessImg1(homeData.acf.process.img1);
      setprocessHeading2(homeData.acf.process.heading2);
      setprocessContent2(homeData.acf.process.content2);
      setprocessImg2(homeData.acf.process.img2);
      setprocessHeading3(homeData.acf.process.heading3);
      setprocessContent3(homeData.acf.process.content3);
      setprocessImg3(homeData.acf.process.img3);
      setAboutHeading(homeData.acf.about_heading);
      setPreAboutHeading(homeData.acf.pre_about_heading);
      setAboutContent(homeData.acf.about_content);
      setInterViewSectionheading(homeData.acf.interview_section_heading);
      setPreHeading(homeData.acf.pre_heading);
      setHeading(homeData.acf.heading);
      setContent(homeData.acf.content);
    }
  }, [homeData]);

  useEffect(() => {
    const home_Page_Images = async () => {
      let homePagesImagesResponse = await fetch(
        `${process.env.REACT_APP_HOST_URL}/wp-json/jobwish/v1/imagesForWeb`
      );
      let $imagesResponse = await homePagesImagesResponse.json();
      // console.log($imagesResponse);
      setBannerImage($imagesResponse.data.Home_Page.homeBannerSlides);
      setImageSlider($imagesResponse.data.Home_Page.homeFirstSectionSlides);
      setAboutImage($imagesResponse.data.Home_Page.home_aboutSectionImg);
      setInterViewSectionImage(
        $imagesResponse.data.Home_Page.home_interviewSectionImg
      );
      setAppImage($imagesResponse.data.Home_Page.home_MobileImage);
    };
    home_Page_Images();
  }, []);

  useEffect(() => {}, [bannerImage]);
  useEffect(() => {}, [imageSlider]);
  useEffect(() => {}, [aboutImage]);
  useEffect(() => {}, [interViewSectionImage]);
  useEffect(() => {}, [appImage]);

  useEffect(() => {
    const jobTotal = async () => {
      // try {
      let jobCountResponse = await fetch(
        `${process.env.REACT_APP_HOST_URL}wp-json/jobwish/v1/jobs`
      );
      let jobCountData = await jobCountResponse.json();
      setJobnumber(jobCountData.data.length);
      // } catch (error) {
      //   console.log("error: ", error);
      // }
    };
    jobTotal();
  }, []);

  useEffect(() => {
    // console.log("length:", jobNumber);
  }, [jobNumber]);

  useEffect(() => {
    const processimg1 = async () => {
      try {
        let processImg1Resp = await fetch(
          `${process.env.REACT_APP_HOST_URL}wp-json/wp/v2/media/${processImg1}`
        );
        let processimg1data = await processImg1Resp.json();
        setprocessImg1link(processimg1data.guid.rendered);
      } catch (error) {
        // console.log("Error :", error);
      }
    };
    processimg1();
  }, [processImg1]);

  useEffect(() => {
    const processimg2 = async () => {
      try {
        let processImg2Resp = await fetch(
          `${process.env.REACT_APP_HOST_URL}wp-json/wp/v2/media/${processImg2}`
        );
        let processimg2data = await processImg2Resp.json();
        setprocessImg2link(processimg2data.guid.rendered);
      } catch (error) {
        // console.log("Error :", error);
      }
    };
    processimg2();
  }, [processImg2]);

  useEffect(() => {
    const processimg3 = async () => {
      try {
        let processImg3Resp = await fetch(
          `${process.env.REACT_APP_HOST_URL}wp-json/wp/v2/media/${processImg3}`
        );
        let processimg3data = await processImg3Resp.json();
        setprocessImg3link(processimg3data.guid.rendered);
      } catch (error) {
        // console.log("Error :", error);
      }
    };
    processimg3();
  }, [processImg3]);

  useEffect(() => {
    const jobTypeDetail = async () => {
      // try {
      let jobTypeResponse = await fetch(
        `${process.env.REACT_APP_HOST_URL}wp-json/jobwish/v1/web-jobType`
      );
      let jobTypeData = await jobTypeResponse.json();
      setJobType(jobTypeData.List || []);
      // } catch (error) {
      //   console.log("Error: ", error);
      // }
    };

    jobTypeDetail();
  }, []);

  useEffect(() => {}, [jobType]);

  useEffect(() => {
    const JobSkillCount = async () => {
      // try {
      let jobSkillResponse = await fetch(
        `${process.env.REACT_APP_HOST_URL}wp-json/jobwish/v1/web-jobCountBySkill`
      );
      let jobSkillData = await jobSkillResponse.json();
      setJobCountWithSkill(jobSkillData.List);
      // } catch (error) {
      //   console.log("Error: ", error);
      // }
    };
    JobSkillCount();
  }, []);

  useEffect(() => {}, [jobCountWithSkill]);

  useEffect(() => {
    const IntrViewQues = async () => {
      // try {
      let intrViewResponse = await fetch(
        `${process.env.REACT_APP_HOST_URL}wp-json/jobwish/v1/web-interviewQuesCount`
      );
      let intrViewData = await intrViewResponse.json();
      setInterviewQues(intrViewData.List);
      // } catch (error) {
      //   console.log("Error: ", error);
      // }
    };
    IntrViewQues();
  }, []);

  useEffect(() => {}, [interviewQues]);

  useEffect(() => {
    const employerCandidateCount = async () => {
      let countResponse = await fetch(
        `${process.env.REACT_APP_HOST_URL}wp-json/jobwish/v1/employerCandidateCount`
      );
      let countRes = await countResponse.json();
      setEmployerCount(countRes.employer_count);
      setCandidateCount(countRes.candidate_count);
    };
    employerCandidateCount();
  }, []);

  useEffect(() => {}, [employerCount]);
  useEffect(() => {}, [candidateCount]);

  useEffect(() => {
    const allSector = async () => {
      let sectorResponse = await fetch(
        `${process.env.REACT_APP_HOST_URL}wp-json/jobwish/v1/jobSectorList`
      );
      let sectorList = await sectorResponse.json();
      // console.log(sectorList);
      setSectorTitle(sectorList.List);
    };
    allSector();
  }, []);

  useEffect(() => {}, [sectorTitle]);

  useEffect(() => {
    const IndustryDetail = async () => {
      let industryResponse = await fetch(
        `${process.env.REACT_APP_HOST_URL}wp-json/jobwish/v1/skill-list`
      );
      let industryList = await industryResponse.json();
      setIndustryTitle(industryList.skill);
    };
    IndustryDetail();
  }, []);

  useEffect(() => {}, [industryTitle]);

  //////////// Search Btn toggle /////////
  let toggleJobSearch = () => {
    setSearchJobBtn(true);
    setSearchCandidateBtn(false);
  };

  let toggleCandidateSearch = () => {
    setSearchCandidateBtn(true);
    setSearchJobBtn(false);
  };

  ///////////// banner slider setting

  const bannerSettings = {
    nav: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  //////////////// Functionality section slider ////
  const funcSettings = {
    nav: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          dots: false,
        },
      },
    ],
  };

  return (
    <>
      <section className="hero">
        <div className="hero__slider">
          <LazyLoad className="home_banner">
            <Slider {...bannerSettings}>
              {/* Render the first static image */}
              <div className="hero__slide" key="static-image">
                <img
                  src={`${process.env.REACT_APP_STAGING_DIR_PATH}/react-app/build/static/media/slider-top.png`}
                  alt=""
                />
              </div>

              {/* Render the images from the API */}
              {bannerImage
                .filter((img) => img !== null)
                .map((ImgLink, index) => (
                  <div className="hero__slide" key={index}>
                    <img src={ImgLink} alt={`home-slider-${index}`} />
                  </div>
                ))}
            </Slider>
          </LazyLoad>
        </div>
        <div className="container overlay-banner-content ">
          <div className="hero__slide__content ">
            <h1 className="bannerHeading">
              <div className="count">
                <CountUp
                  className="count"
                  start={0}
                  end={jobNumber}
                  duration={2}
                />
                Job verfügbar
              </div>

              <div className="count">
                <CountUp
                  className="count"
                  start={0}
                  end={employerCount}
                  duration={2}
                />
                Arbeitgeberregister
              </div>

              <div className="count">
                <CountUp
                  className="count"
                  start={0}
                  end={candidateCount}
                  duration={2}
                />
                Kandidatenregister
              </div>
            </h1>
          </div>
          <div className="bannerWrapper">
            <h1 className="bannerTitles">{bannerHeading}</h1>
            <p>{bannerContent}</p>
          </div>
          <div className="row">
            <div className="btn-wrp">
              <button onClick={toggleJobSearch}>Search&nbsp;Job</button>
              <button onClick={toggleCandidateSearch}>
                Search Candidate
              </button>{" "}
            </div>
            {searchJobBtn ? (
              <form className="search-container">
                <div className="input">
                  <input
                    type="text"
                    className="messageHere"
                    placeholder="Job Title for Keyword"
                    onChange={(e) => {
                      setJobTitle(e.target.value);
                    }}
                  />
                  {/* <input
                  type="search"
                  className="messageHere mobile-view"
                  placeholder="Search Here..."
                /> */}
                </div>

                <div className="input">
                  <input
                    type="text"
                    className="sBtn-text"
                    placeholder="Enter Location"
                    onChange={(e) => {
                      setJobLocation(e.target.value);
                    }}
                  />
                </div>

                <div className="select-menu-2">
                  <div className="select-btn-2">
                    <select
                      name="jobType"
                      onChange={(e) => {
                        setJobSearchtype(e.target.value);
                      }}
                    >
                      <option value="Select">Job Type</option>
                      {jobType.map((typeList, i) => (
                        <option value={typeList} key={i}>
                          {typeList}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="search-btn">
                  <Link
                    className="search-form-btn"
                    type="submit"
                    to={`/searchjobresult/${jobTitle}/${jobLocation}/${jobSearchtype}`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="72"
                      height="72"
                      viewBox="0 0 74 74"
                      fill="none"
                    >
                      <rect width="74" height="74" rx="37" fill="#B21918" />
                      <path
                        d="M54.7537 53.2255C54.7435 53.2297 54.7265 53.2305 54.7231 53.2381C54.1119 54.4949 53.0688 54.6643 52.0598 53.6693C48.9238 50.5761 45.787 47.4845 42.6588 44.3845C42.4624 44.1899 42.3451 44.1555 42.1088 44.3375C40.3397 45.6992 38.3404 46.5708 36.1327 46.9459C33.3308 47.4216 30.6216 47.0969 28.0323 45.9123C25.2882 44.6563 23.1724 42.7183 21.7103 40.1133C20.3688 37.7223 19.8146 35.1492 20.0543 32.415C20.2974 29.6456 21.3116 27.1782 23.0848 25.0355C25.2449 22.4246 28.0255 20.8096 31.3944 20.2039C31.8475 20.1225 32.3065 20.0671 32.7621 20C33.4405 20 34.1197 20 34.7981 20C34.8822 20.0738 34.9893 20.0579 35.088 20.0654C36.9726 20.2215 38.739 20.7828 40.3814 21.6855C43.0268 23.1402 44.9973 25.2335 46.269 27.9542C47.4591 30.5005 47.783 33.1625 47.3044 35.916C46.9261 38.0948 46.0387 40.0655 44.6649 41.8139C44.4881 42.0388 44.4983 42.1562 44.7032 42.3576C47.8043 45.4038 50.8943 48.461 53.9929 51.5115C54.3074 51.8211 54.5964 52.1382 54.7528 52.5552V53.2247L54.7537 53.2255ZM44.6054 33.5778C44.6139 27.6874 39.7362 22.8726 33.7593 22.8718C27.7815 22.8718 22.9131 27.674 22.9123 33.5753C22.9106 39.4724 27.7841 44.283 33.7576 44.2813C39.7345 44.2796 44.5969 39.4824 44.6054 33.5778Z"
                        fill="white"
                      />
                    </svg>
                  </Link>
                </div>
              </form>
            ) : null}
            {searchCandidateBtn ? (
              <form className="search-container candidate-search">
                <div className="input">
                  <select
                    name="sector"
                    onChange={(e) => {
                      setSectorName(e.target.value);
                    }}
                  >
                    <option value="Select">Sector List</option>
                    {sectorTitle.map((typeList, i) => (
                      <option value={typeList} key={i}>
                        {typeList}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input">
                  <select
                    name="industry"
                    onChange={(e) => {
                      setIndustryName(e.target.value);
                    }}
                  >
                    <option value="Select">Skill List</option>
                    {industryTitle.map((typeList, i) => (
                      <option value={typeList} key={i}>
                        {typeList}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="select-menu-2">
                  <div className="select-btn-2">
                    <input
                      type="text"
                      className="messageHere"
                      placeholder="Location"
                      onChange={(e) => {
                        setJobLocation(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="search-btn">
                  <Link
                    className="search-form-btn"
                    type="submit"
                    to={`/SearchCandidateResult/${sectorName}/${industryName}/${jobLocation}`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="72"
                      height="72"
                      viewBox="0 0 74 74"
                      fill="none"
                    >
                      <rect width="74" height="74" rx="37" fill="#B21918" />
                      <path
                        d="M54.7537 53.2255C54.7435 53.2297 54.7265 53.2305 54.7231 53.2381C54.1119 54.4949 53.0688 54.6643 52.0598 53.6693C48.9238 50.5761 45.787 47.4845 42.6588 44.3845C42.4624 44.1899 42.3451 44.1555 42.1088 44.3375C40.3397 45.6992 38.3404 46.5708 36.1327 46.9459C33.3308 47.4216 30.6216 47.0969 28.0323 45.9123C25.2882 44.6563 23.1724 42.7183 21.7103 40.1133C20.3688 37.7223 19.8146 35.1492 20.0543 32.415C20.2974 29.6456 21.3116 27.1782 23.0848 25.0355C25.2449 22.4246 28.0255 20.8096 31.3944 20.2039C31.8475 20.1225 32.3065 20.0671 32.7621 20C33.4405 20 34.1197 20 34.7981 20C34.8822 20.0738 34.9893 20.0579 35.088 20.0654C36.9726 20.2215 38.739 20.7828 40.3814 21.6855C43.0268 23.1402 44.9973 25.2335 46.269 27.9542C47.4591 30.5005 47.783 33.1625 47.3044 35.916C46.9261 38.0948 46.0387 40.0655 44.6649 41.8139C44.4881 42.0388 44.4983 42.1562 44.7032 42.3576C47.8043 45.4038 50.8943 48.461 53.9929 51.5115C54.3074 51.8211 54.5964 52.1382 54.7528 52.5552V53.2247L54.7537 53.2255ZM44.6054 33.5778C44.6139 27.6874 39.7362 22.8726 33.7593 22.8718C27.7815 22.8718 22.9131 27.674 22.9123 33.5753C22.9106 39.4724 27.7841 44.283 33.7576 44.2813C39.7345 44.2796 44.5969 39.4824 44.6054 33.5778Z"
                        fill="white"
                      />
                    </svg>
                  </Link>
                </div>
              </form>
            ) : null}
          </div>
        </div>
      </section>

      {/* <!-- header-space --> */}
      <section className="normal">
        <div className="container">
          <div className="row">
            <div className="com-sec">
              <div className="com-text col-5">
                <span>{preHeadingSection}</span>
                <h3>{headingSection}</h3>
                <div className="trade">
                  <div className="fly">
                    <div className="blending">
                      <LazyLoad className="bending_img">
                        <img src={processImg1link} alt="Group 571" />
                      </LazyLoad>
                      <div className="imone">
                        <h5>{processHeading1}</h5>
                        <p
                          dangerouslySetInnerHTML={{ __html: processContent1 }}
                        />
                      </div>
                    </div>
                    <div className="blending">
                      <LazyLoad className="bending_img">
                        <img src={processImg2link} alt="Group 570" />
                      </LazyLoad>
                      <div className="imone">
                        <h5>{processHeading2}</h5>
                        <p
                          dangerouslySetInnerHTML={{ __html: processContent2 }}
                        />
                      </div>
                    </div>
                    <div className="blending">
                      <LazyLoad className="bending_img">
                        <img src={processImg3link} alt="Group 570" />
                      </LazyLoad>
                      <div className="imone">
                        <h5>{processHeading3}</h5>
                        <p
                          dangerouslySetInnerHTML={{ __html: processContent3 }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="img-wrapper col-6 slider_sec">
                <LazyLoad className="home_2_slider img-wrapper">
                  <Slider {...funcSettings}>
                    {imageSlider
                      .filter((img) => img !== null)
                      .map((ImgLink, index) => (
                        <div className="com-img">
                          <img key={index} src={ImgLink} alt="home_2_slider" />
                        </div>
                      ))}
                  </Slider>
                </LazyLoad>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="majority-sec">
        <div className="container">
          <div className="row">
            <div className="majority-wrap">
              <div className="majority-content">
                <span>{preAboutHeading}</span>
                <h3>{aboutHeading}</h3>
                <p>{aboutContent}</p>

                <Link to="/about" className="read-more">
                  READ MORE
                </Link>
              </div>
              <div className="majority-img">
                {/* <LazyLoad className="about_sec majority-img"> */}
                <img src={aboutImage} alt="aboutImage" />
                {/* </LazyLoad> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="preview-sec">
        <div className="container">
          <div className="row">
            <div className="preview-wrap">
              <div className="table-image">
                <LazyLoad className="interViewImg">
                  <img src={interViewSectionImage} alt="interView_img" />
                </LazyLoad>
                <h3>{interViewSectionheading}</h3>
              </div>
            </div>
            <div className="card-row desktop-view">
              <div className="two-cards-wrp">
                <div className="new-jobs">
                  <h2>New Jobs Availables</h2>
                  <div className="new-jobs-inside">
                    {jobCountWithSkill.slice(0, 6).map((item, i) => (
                      <Link to={`/skilljobresult/${item.slug}/${item.count}`}>
                        <div className="jobs" key={i}>
                          <img
                            src={require("../assets/images/Frame 1000005054 (2).png")}
                            alt=""
                          />
                          <div className="job-text">
                            <h3>{item.slug}</h3>
                            <span>{item.count} Jobs</span>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                  <div className="card-bottom-btn">
                    <Link to={`/allcompanieslist`} className="view">
                      Alle Unternehmen anzeigen
                    </Link>
                  </div>
                </div>
              </div>
              <div className="two-cards-wrp-sec ">
                <div className="new-jobs">
                  <h2>Interview question by role</h2>
                  <div className="card-text-row">
                    {interviewQues.slice(0, 6).map((item, i) => (
                      <Link
                        to={`/interviewquesresult/${item.slug}/${item.count}`}
                      >
                        <h3 key={i}>
                          {item.slug} <span>({item.count} Fragen)</span>
                        </h3>
                      </Link>
                    ))}
                  </div>
                  <div className="card-bottom-btn">
                    <Link to={`/allrolelist`} className="view">
                      Alle Rollen anzeigen
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="preview-sec preview-sec2 ">
        <div className="container">
          <div className="card-row ">
            <div className="two-cards-wrp">
              <div className="new-jobs">
                <h3>Neue Jobs verfügbar</h3>
                <div className="new-jobs-inside">
                  {jobCountWithSkill.slice(0, 6).map((item, i) => (
                    <Link to={`/skilljobresult/${item.slug}/${item.count}`}>
                      <div className="jobs" key={i}>
                        <img
                          src={require("../assets/images/Frame 1000005054 (2).png")}
                          alt=""
                        />
                        <div className="job-text">
                          <h3>{item.slug}</h3>
                          <span>{item.count} Jobs</span>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
                <div className="card-bottom-btn">
                  <Link to={`/allcompanieslist`} className="view">
                    Alle Unternehmen anzeigen
                  </Link>
                </div>
              </div>
            </div>
            <div className="two-cards-wrp-sec">
              <div className="new-jobs">
                <h2>Interviewfrage nach Rolle</h2>
                <div className="card-text-row">
                  {interviewQues.slice(0, 6).map((item, i) => (
                    <Link
                      to={`/interviewquesresult/${item.slug}/${item.count}`}
                    >
                      <h3 key={i}>
                        {item.slug} <span>({item.count} Fragen)</span>
                      </h3>
                    </Link>
                  ))}
                </div>
                <div className="card-bottom-btn">
                  <Link to={`/allrolelist`} className="view">
                    Alle Rollen anzeigen
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="download">
        <div className="container">
          <div className="row justify-content-center">
            <div className="download-wrap">
              <div className="img-side">
                <LazyLoad className="appImageLink">
                  <img src={appImage} alt="app_img" />
                </LazyLoad>
              </div>
              <div
                className="download-content  fadeInUp wow   animated"
                data-wow-duration="3s"
              >
                <span>{preHeading}</span>
                <h2>{heading}</h2>
                <p>{content}</p>
                <div
                  className="download-channel fadeInUp wow   animated"
                  data-wow-duration="3s"
                >
                  <img
                    src={`${process.env.REACT_APP_STAGING_DIR_PATH}/react-app/build/static/media/apple-app-store-logo-1.png`}
                    alt=""
                    className="appstore"
                  />

                  <img
                    src={`${process.env.REACT_APP_STAGING_DIR_PATH}/react-app/build/static/media/google-play-badge-1.png`}
                    alt=""
                    className="google-store"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Home;
