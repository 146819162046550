import React, { useEffect, useState } from "react";
import LazyLoad from "react-lazy-load";
import { Link } from "react-router-dom";

function AllCompaniesList() {
  let [jobCountWithSkill, setJobCountWithSkill] = useState([]);
  const [bannerTitle, setBannerTitle] = useState();
  const [bannerImg, setBannerImg] = useState();
  let [visibleList, setVisibleList] = useState(10);

  useEffect(() => {
    const JobSkillCount = async () => {
      // try {
        let jobSkillResponse = await fetch(
          `${process.env.REACT_APP_HOST_URL}wp-json/jobwish/v1/web-jobCountBySkill`
        );
        let jobSkillData = await jobSkillResponse.json();
        setJobCountWithSkill(jobSkillData.List);
      // } catch (error) {
      //   console.log("Error: ", error);
      // }
    };
    JobSkillCount();
  }, []);

  useEffect(() => {}, [jobCountWithSkill]);

  useEffect(() => {
    const bannerDetail = async () => {
      // try {
        let bannerResponse = await fetch(
          `${process.env.REACT_APP_HOST_URL}wp-json/wp/v2/pages/667`
        );
        let bannerData = await bannerResponse.json();
        setBannerTitle(bannerData.title.rendered);
      // } catch (error) {
      //   // console.log("Error: ",error);
      // }
    };
    bannerDetail();
  }, []);

  useEffect(()=>{
    const ACL_page_images = async () => {
      let ACLpageimagesResponse = await fetch(`${process.env.REACT_APP_HOST_URL}/wp-json/jobwish/v1/imagesForWeb`);
      let imagesResponse = await ACLpageimagesResponse.json();
      setBannerImg(imagesResponse.data.ACL_page);
    }
    ACL_page_images();
  },[]);

  useEffect(() => {}, [bannerImg, bannerTitle]);

  function LoadMorelist() {
    setVisibleList((preValue) => preValue + 4);
  }

  return (
    <div>
      <section className="job-list-hero blog-hero">
        <LazyLoad className="AllCompImg blog-hero">
          <img src={bannerImg} alt="" />
        </LazyLoad>
        <div className="bnr-blog-content">
          <h1>{bannerTitle}</h1>
        </div>
      </section>
      <section className="new-jobs-section">
        <div className="container">
          <div className="new-jobs">
            <h3>New Jobs Availables</h3>
            <div className="items">
              {jobCountWithSkill.slice(0, visibleList).map((item, i) => (
                <div className="item-card">
                  <Link to={`/skilljobresult/${item.slug}/${item.count}`}>
                    <div className="jobs" key={i}>
                      {/* <img src={require('../assets/images/div.png')} alt="" /> */}
                      <div className="job-text">
                        <h3>{item.slug}</h3>
                        <span>{item.count} Jobs</span>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      {visibleList < jobCountWithSkill.length ? (
      <button className="btn btn-red mx-auto my-5" onClick={LoadMorelist}>Load More</button>
    ) : null}
    </div>
  );
}

export default AllCompaniesList;
