import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [headerDetail, setHeaderDetail] = useState([]);
  const [topHeader, setTopHeader] = useState([]);
  const [siteLogo, setSiteLogo] = useState("");
  const [headerLink, setHeaderLink] = useState([]);
  const [headerSelectLink, setHeaderSelectLink] = useState([]);

  const sidePanelRef = useRef(null);
  const openNav = () => {
    if (sidePanelRef.current) {
      sidePanelRef.current.style.height = "100vh";
      setIsOpen(true);
    }
  };

  const closeNav = () => {
    if (sidePanelRef.current) {
      sidePanelRef.current.style.height = "0";
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_HOST_URL}wp-json/jobwish/v1/site-header`
      );
      const res = await response.json();
      setHeaderDetail(res);
    };
    fetchData();
  }, []);

  useEffect(() => {
    // console.log(headerDetail.detail);
    if (headerDetail && headerDetail.detail) {
      setTopHeader(headerDetail.detail.top_header);
      setSiteLogo(headerDetail.detail.site_logo);
      setHeaderLink(headerDetail.detail.header_nav.links);
      setHeaderSelectLink(headerDetail.detail.header_nav.select_links);
    }
  }, [headerDetail]);
  console.log(headerSelectLink);
  useEffect(() => {}, [topHeader, siteLogo, headerLink, headerSelectLink]);

  return (
    <>
      {/* <!-- header --> */}

      <div className="header">
        <div className="top-bar">
          <ul className="top-bar-menus">
            {topHeader.map((item, i) => (
              <li key={i}>{item}</li>
            ))}
          </ul>
        </div>
        <div className="container">
          <div className="row upperHeader">
            <div className="logo_div">
              <h2 className="logo">
                <Link to={`/`}>{<img src={siteLogo} alt="" />}</Link>
              </h2>
            </div>
            <button className="openbtn" onClick={isOpen ? closeNav : openNav}>
              {" "}
              ☰{" "}
            </button>

            <nav className="navbar">
              <ul className="menu">
                {headerLink.map((item, i) => (
                  <li key={i} className={item.single_link.title === "Login" ? "userdetailslogin" : ""}>
                    <a className="active" href={item.single_link.url}>
                      {item.single_link.title}
                    </a>
                  </li>
                ))}
                <li>
                  <div className="dropdown">
                    <a
                      className="dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Mein Konto
                    </a>
                    <ul className="dropdown-menu">
                      {headerSelectLink.map((item, i) => (
                        <li key={i}>
                          <a class={item.link.title === "Benutzer-Dashboard" ? "dropdown-item Benutzer-Dashboard" : "dropdown-item" } href={item.link.url}>
                            {item.link.title}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="mobile-menu">
          <div
            ref={sidePanelRef}
            className="sidepanel"
            style={{ height: isOpen ? "100vh" : "0" }}
          >
            <div className="log-box-mobile">
              <div className="logo">
                <span>{<img src={siteLogo} alt="site_logo" />}</span>
              </div>
              <div className="closebtn-cta">
                <a
                  href="javascript:void(0)"
                  className="closebtn"
                  onClick={closeNav}
                >
                  ×
                </a>
              </div>
            </div>

            <ul className="menu">
              {headerLink.map((item, i) => (
                <li key={i}>
                  <a className="active" href={item.single_link.url}>
                    {item.single_link.title}
                  </a>
                </li>
              ))}
              <li>
                <div class="dropdown">
                  <a
                    class="dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    My Account
                  </a>
                  <ul class="dropdown-menu">
                    {headerSelectLink.map((item, i) => (
                      <li key={i}>
                        <a class="dropdown-item" href={item.link.url}>
                          {item.link.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
export default Header;
