import React, { useEffect, useState } from "react";

function LoginPopup(props) {
  //   console.log(props);
  const [userEmail, setUserEmail] = useState();
  const [userPass, setUserPass] = useState();
  const [loginRes,setLoginRes] = useState();

  const login_Sumbit = (e) => {
    e.preventDefault();
    
    if(userEmail !== "" && userPass !== ""){
      // console.log(userEmail, userPass);
      const checkLogin = async ()=>{
        const response = await fetch(`${process.env.REACT_APP_HOST_URL}wp-json/jobwish/v1/websiteloginapi`,{
          method: 'POST',
          headers: {
            'Content-Type':'application/json'
          },
          body:JSON.stringify({
            useremail:userEmail,
            userpassword:userPass
          })
        });
        const result = await response.json();
        // console.log("result",result);
        setLoginRes(result);
      document.querySelector('.loginPopup-inner').style.display = 'none';
      }      
      checkLogin();
    } else {
      console.log('Field required');
      document.querySelector('.loginPopup-inner').style.display = 'none';
      document.querySelector('.inputfieldRequired').style.display = 'block';
    }
  };


  useEffect(()=>{
    if(loginRes){
      // console.log(loginRes.message);
      document.querySelector('.loginResponse').style.display = 'block';
      document.querySelector('.loginResponse').innerHTML= loginRes.message;
      setTimeout(() => {
        setTimeout(()=>{
          document.querySelector('.loginResponse').style.display = 'none';
        },2000);
        window.location.reload();
      }, 4000);
     
    } 

  },[loginRes]);

  return props.trigger ? (
    <div className="loginPopup">
      <div className="loginPopup-inner">
        {props.children}
        <button
          className="close-btn"
          onClick={() => {
            props.setTrigger(false);
          }}
        >
          X
        </button>
        <form>
          <label>Email</label>
          <input
            type="email"
            onChange={(e) => {
              setUserEmail(e.target.value);
            }}
            placeholder="Enter email"
            required
          />
          <label>Password</label>
          <input
            type="password"
            onChange={(e) => {
              setUserPass(e.target.value);
            }}
            placeholder="Enter password"
            required
          />
          <input type="submit" onClick={login_Sumbit} />
        </form>
      </div>
      <div className="inputfieldRequired" style={{display:"none"}}>Email and Password field required!</div>
      <div className="loginResponse" style={{display:"none"}}></div>
    </div>
  ) : (
    ""
  );
}

export default LoginPopup;
