import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./Web-component/Footer";
import Header from "./Web-component/Header";
import Home from "./Web-component/Home";
import Testimonial from "./Web-component/Testimonial";
import AboutUS from "./Web-component/AboutUs";
import FAQ from "./Web-component/FAQ";
import Blog from "./Web-component/Blog";
import PostDetail from "./Web-component/PostDetail";
import SearchJobResult from "./Web-component/SearchJobResult";
import SkillJobResult from "./Web-component/SkillJobResult";
import InterViewQuesResult from "./Web-component/InterViewQuesResult";
import AllCompaniesList from "./Web-component/AllCompaniesList";
import AllRoleList from "./Web-component/AllRoleList";
import ScrollToTop from "./Web-component/ScrollToTop";
import SearchCandidateResult from "./Web-component/SearchCandidateResult";
// import GTM from "./Web-component/gtm";

function App() {
  // const gtmId = "GTM-T433G3P2"; // Replace with your GTM ID
  return (
    <ScrollToTop>
      <div className="App">
        {/* <GTM gtmId={gtmId} /> */}
        <Header />
        <Routes basename="/homepage">
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUS />} />
          <Route path="/faqs" element={<FAQ />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/postdetail/:postId?" element={<PostDetail />} />
          <Route
            path="/searchjobresult/:jobTitle?/:jobLocation?/:jobType?"
            element={<SearchJobResult />}
          />
          <Route
            path="/SearchCandidateResult/:sectorName?/:industryName?/:jobLocation?"
            element={<SearchCandidateResult />}
          />
          <Route
            path="/skilljobresult/:slug?/:count?"
            element={<SkillJobResult />}
          />
          <Route
            path="/interviewquesresult/:slug?/:count?"
            element={<InterViewQuesResult />}
          />
          <Route path="/allcompanieslist" element={<AllCompaniesList />} />
          <Route path="/allrolelist" element={<AllRoleList />} />
          <Route path="/*" element={<Navigate to="/" />} />
        </Routes>
        <Testimonial />
        <Footer />
      </div>
    </ScrollToTop>
  );
}

export default App;
