import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Footer() {
  const [footerData, setFooterData] = useState("");
  let [footerHeading, setFooterHeading] = useState("");
  let [footerContent, setFooterContent] = useState("");
  let [instagramLink, setinstagramLink] = useState("");
  let [facebookLink, setfacebookLink] = useState("");
  let [linkedinLink, setlinkedinLink] = useState("");
  let [pinterestLink, setpinterestLink] = useState("");
  let [TwitterLink, setTwitterLink] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_HOST_URL}wp-json/wp/v2/pages/356`
        );
        const data = await response.json();
        setFooterData(data);
      } catch (error) {
        // console.error("Error: ", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (footerData && footerData.acf) {
      // console.log(footerData.acf);
      setFooterHeading(footerData.acf.footer_heading);
      setFooterContent(footerData.acf.footer_content);
      setinstagramLink(footerData.acf.social_media_links.instagram.url);
      setfacebookLink(footerData.acf.social_media_links.facebook.url);
      setlinkedinLink(footerData.acf.social_media_links.linkedin.url);
      setpinterestLink(footerData.acf.social_media_links.pinterest.url);
      setTwitterLink(footerData.acf.social_media_links.twitter.url);
    }
  }, [footerData]);

  const currentYear = new Date().getFullYear();

  const iframeUrl = `${process.env.REACT_APP_HOST_URL}/contact-form/`;

  return (
    <footer className="footer">
      <div className="container">

        <div className="footer-wrp">
          <div className="d-flex flex-wrap justify-content-between">
            <div className="col-md-4 ">
              <h4>{footerHeading}</h4>

              <p className="footer_desc">{footerContent}</p>
              <div className="social-icons">
                <a href={instagramLink} target="_blank">
                  <img
                    src={`${process.env.REACT_APP_STAGING_DIR_PATH}/react-app/build/static/media/_Instagram.svg`}
                    alt="instagramImage"
                  />
                </a>
                <a href={facebookLink} target="_blank">
                  <img
                    src={`${process.env.REACT_APP_STAGING_DIR_PATH}/react-app/build/static/media/_Facebook.svg`}
                    alt="facebookImage"
                  />
                </a>
                <a href={linkedinLink} target="_blank">
                  <img
                    src={`${process.env.REACT_APP_STAGING_DIR_PATH}/react-app/build/static/media/_Linkedin.svg`}
                    alt="linkedinImage"
                  />
                </a>
                <a href={pinterestLink} target="_blank">
                  <img
                    src={`${process.env.REACT_APP_STAGING_DIR_PATH}/react-app/build/static/media/_Pinterest.svg`}
                    alt="pinterestImage"
                  />
                </a>
                <a href={TwitterLink} target="_blank">
                  <img
                    src={`${process.env.REACT_APP_STAGING_DIR_PATH}/react-app/build/static/media/_Twitter.svg`}
                    alt="TwitterImage"
                  />
                </a>
              </div>
            </div>
            <div className="col-md-7 desktop-view">
              <div className="d-flex flex-wrap">
                <div className="col-md-4 footer-list">
                  <h5>Company </h5>
                  <ul className="list-style ">
                    <li>
                      <Link to="/about">About Us</Link>
                    </li>
                    <li>Freebies</li>
                    <li>Premium Tools</li>
                    <li>
                      <Link to="/blog">Blog</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-4 footer-list">
                  <h5> Help & Support</h5>
                  <ul className="list-style">
                    <li>Contact Us</li>
                    <li>Knowledge Center</li>
                    <li>Custom Development</li>
                    <li>Sponsorships</li>
                  </ul>
                </div>
                <div className="col-md-4 footer-list">
                  <h5>Legal </h5>
                  <ul className="list-style">
                    <li><a href="https://jobwish.ch/term-conditions/">Terms & Conditions</a></li>
                    <li><a href="https://jobwish.ch/privacy-policy/">Privacy Policy</a></li>
                    <li>Licenses (EULA)</li>
                    <li>
                      <Link to="/faqs">FAQ</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row stillHaveRow ">
            <div className="col-md-4 stillHaveQues">
              <h4>Still have questions? Contact our team</h4>
              <div className="mobile-footer-menu mobile-view">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                      >
                        Company
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          <Link to="/about">About Us</Link>
                        </p>
                        <p>
                          <a href="#">Freebies</a>
                        </p>
                        <p>
                          <a href="#">Premium Tools</a>
                        </p>
                        <p>
                          <Link to="/blog">Blog</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Legal
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          <a href="https://jobwish.ch/term-conditions/">Terms & Conditions</a>
                        </p>
                        <p>
                          <a href="https://jobwish.ch/privacy-policy/">Privacy Policy</a>
                        </p>
                        <p>
                          <a href="#">Licenses (EULA)</a>
                        </p>
                        <p>
                          <Link to="/faqs">FAQ</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        Help & Support
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          <a href="#">Contact Us</a>
                        </p>
                        <p>
                          <a href="#">Knowledge Center</a>
                        </p>
                        <p>
                          <a href="#">Custom Development</a>
                        </p>
                        <p>
                          <a href="#">Sponsorships</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-7 p-0">
              <form className="search-container">
                <label className="">Your Message:</label>
                <div className="align-items-center">
                  <iframe sandbox="allow-forms allow-modals allow-pointer-lock allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation allow-downloads allow-presentation" scrolling="auto" 
                    title="Contact Form"
                    src={iframeUrl}
                    width="100%"
                    height="auto"
                    frameBorder="0"
                  ></iframe>
                </div>
              </form>
            </div>
          </div>
          <div className="copyright">
            © {currentYear} All Rights Reserved by JobWish
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
