import React, { useEffect, useState } from "react";
import LazyLoad from "react-lazy-load";
import { Link } from "react-router-dom";

function AllRoleList() {
  let [interviewQues, setInterviewQues] = useState([]);
  const [bannerTitle, setBannerTitle] = useState();
  const [bannerImg, setBannerImg] = useState();
  let [visibleList, setVisibleList] = useState(10);

  useEffect(() => {
    const IntrViewQues = async () => {
      // try {
      let intrViewResponse = await fetch(
        `${process.env.REACT_APP_HOST_URL}wp-json/jobwish/v1/web-interviewQuesCount`
      );
      let intrViewData = await intrViewResponse.json();
      setInterviewQues(intrViewData.List);
      // } catch (error) {
      //   console.log("Error: ", error);
      // }
    };
    IntrViewQues();
  }, []);

  useEffect(() => {}, [interviewQues]);

  useEffect(() => {
    const bannerDetail = async () => {
      try {
        let bannerResponse = await fetch(
          `${process.env.REACT_APP_HOST_URL}wp-json/wp/v2/pages/669`
        );
        let bannerData = await bannerResponse.json();
        setBannerTitle(bannerData.title.rendered);
        let bannerImg = await fetch(
          `${process.env.REACT_APP_HOST_URL}wp-json/wp/v2/media/${bannerData.featured_media}`
        );
        let bannerImgData = await bannerImg.json();
        setBannerImg(bannerImgData.guid.rendered);
      } catch (error) {
        // console.log("Error: ",error);
      }
    };
    bannerDetail();
  }, []);

  useEffect(() => {}, [bannerImg, bannerTitle]);

  function LoadMorelist() {
    setVisibleList((preValue) => preValue + 4);
  }

  return (
    <div>
      <section className="job-list-hero blog-hero">
        <LazyLoad className="aboutBanrImg blog-hero">
          <img src={bannerImg} alt="" />
        </LazyLoad>
        <div className="bnr-blog-content">
          <h1>{bannerTitle}</h1>
        </div>
      </section>
      <div className="new-jobs-section">
        <div className="container">
          <div className="new-jobs">
            <h3>Interview question by role</h3>
            <div className="items">
              {interviewQues.slice(0, visibleList).map((item, i) => (
                <div className="item-card">
                  <Link to={`/interviewquesresult/${item.slug}/${item.count}`}>
                    <div className="jobs">
                      <div className="job-text">
                        <h3 className="mb-2" key={i}>
                          {item.slug}
                        </h3>
                        <span>({item.count} questions)</span>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
        {visibleList < interviewQues.length ? (
          <button className="btn btn-red mx-auto my-4" onClick={LoadMorelist}>
            Load More
          </button>
        ) : null}
      </div>
    </div>
  );
}

export default AllRoleList;
